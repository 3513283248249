
import { Options, Vue } from "vue-class-component";
import { Album } from "@/types/Album";
import AlbumService from "@/services/AlbumService";
import AlbumsListItem from "@/components/AlbumsListItem.vue";

@Options({
  components: {
    AlbumsListItem
  }
})
export default class AlbumsList extends Vue {
  //properties
  albums!: [Album];
  loading!: boolean;
  errored!: boolean;

  data() {
    return {
      albums: null,
      loading: true,
      errored: false
    };
  }

  mounted() {
    AlbumService.listAlbums()
      .then(response => {
        this.albums = response;
      })
      .catch(error => {
        this.errored = true;
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
