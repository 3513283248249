
import { Vue } from 'vue-class-component';

export default class AppHeader extends Vue {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      supportUA: 'Stand For Ukraine'
    };
  }

  //computed properties
  get isAnAlbum() {
    return this.$route.params.folder;
  }

  get albumPath() {
    return "/album/" + this.$route.params.folder;
  }

  get albumName() {
    return this.$route.params.folder;
  }
}
