

import { Options, Vue } from 'vue-class-component';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

@Options({
  components: {
    AppFooter,
    AppHeader
  }
})
export default class App extends Vue {
  // setTitle(pageName: string) {
  //   const titleEl = document.querySelector('head title');
  //   if (titleEl != null) {
  //     titleEl.textContent = process.env.VUE_APP_TITLE + " - " + pageName;
  //   }
  // }

  // created() {
  //   const titleEl = document.querySelector('head title');
  //   if (titleEl != null) {
  //     titleEl.textContent = process.env.VUE_APP_TITLE;
  //   }
  // }
}

