
import { Options, Vue } from "vue-class-component";
import { Album } from "@/types/Album";

@Options({
  props: {
    album: {} as Album
  }
})
export default class AlbumsListItem extends Vue {
  //properties
  album!: Album;

  //computed properties
  get isValidAlbum() {
    return this.album && this.album.folder && this.album.images.length > 0;
  }

  get hasCaption() {
    return this.album.caption && true;
  }

  get url() {
    return "/album/" + this.album.folder;
  }

  imageSourceByWitdh(width: number) {
    const image = this.album.images[0];
    const extension = (image.extension === undefined) ? ".jpg" :  image.extension;

    return "assets/images/albums/" + this.album.folder + "/" + image.filename + "-" + width + "w" + extension;
  }
}
